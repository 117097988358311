import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../Page";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Widget from "../Components/Widget";
import * as Icon from "../Components/Icon";
import TagItem from "../Components/TagItem";

class EditTagPage extends Component {
  state = {
    originData: {},
    data: {
      name: "",
      url: ""
    },
    errorMsg: ""
  };

  componentDidMount() {
    let { appActions, profile, uid } = this.props;

    if (profile) {
      appActions
        .getTag(uid)
        .then(result => this.setState({ originData: result }))
        .catch(err => console.log(err));
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.profile && this.props.profile) {
      let { appActions, uid } = this.props;
      appActions
        .getTag(uid)
        .then(result => this.setState({ originData: result }))
        .catch(err => console.log(err));
    }
  }

  render() {
    let { navActions, appActions, profile, uid } = this.props;
    let { originData, data, errorMsg } = this.state;

    return (
      <Wrapper>
        <div className="container">
          <div className="backbutton" onClick={() => navActions.push("/me")}>
            <Icon.ArrowBack color="white" />
          </div>
          <h1>{`TAG\nSETTING`}</h1>
          {profile ? (
            <>
              <Widget.Row
                align="flex-end"
                justify="center"
                extraCss="width: 100%; padding: 20px 15px 10px;"
              >
                <TagItem tag={originData} isOnlyRead />
                <Widget.Input
                  value={data.name || ""}
                  placeholder="TAG名稱"
                  extraCss="margin-left: 30px"
                  onChange={this._onInputUpdate("name")}
                />
              </Widget.Row>
              <Widget.Col
                align="flex-start"
                extraCss="max-width: 300px; width: 100%; padding: 10px 15px;"
              >
                <p
                  style={{
                    fontSize: 14,
                    color: "#224E86",
                    lineHeight: "20px"
                  }}
                >
                  綁定的URL：
                </p>
                <a
                  href={originData.url}
                  style={{
                    fontSize: 14,
                    color: "#224E86",
                    lineHeight: "20px",
                    wordWrap: "break-word"
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {originData.url}
                </a>
              </Widget.Col>
              <div
                style={{
                  padding: "0px 15px 20px",
                  maxWidth: 300,
                  width: "100%"
                }}
              >
                <Widget.IconInput
                  icon={"/images/link.png"}
                  value={data.url || ""}
                  onChange={this._onInputUpdate("url")}
                />
              </div>
              {errorMsg && (
                <div style={{ fontSize: 14, color: "red" }}>{errorMsg}</div>
              )}
              <Widget.Row
                justify="flex-end"
                extraCss="max-width: 300px; width: 100%;"
              >
                <Widget.SubmitButton
                  text="儲存"
                  onClick={() => this._onSubmit()}
                />
              </Widget.Row>
            </>
          ) : (
            <div>請先登入！</div>
          )}
        </div>
      </Wrapper>
    );
  }

  _onInputUpdate = key => e => {
    let { data } = this.state;
    this.setState({
      data: { ...data, [key]: e.target.value }
    });
  };

  _onSubmit = () => {
    let { appActions, navActions, uid } = this.props;
    let { data, originData } = this.state;

    if (!data.name && !data.url) {
      return this.setState({ errorMsg: "請至少填入一項資料進行修改！" });
    }

    if (data.name === "") {
      data.name = originData.name;
    }

    if (data.url === "") {
      data.url = originData.url;
    }

    appActions
      .editTag({
        ...data,
        uid
      })
      .then(result => {
        this.setState({ data: {} });
        navActions.push("/success");
      })
      .catch(result => console.log(">>>> error ui hint"));
  };
}

const Wrapper = styled.div`
  position: relative;
  background-image: url("/images/setting-bg.png");
  background-repeat: no-repeat;
  background-position: left bottom;

  & .backbutton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #72a2dd;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    z-index: 2;

    position: absolute;
    top: 10px;
    left: 10px;
  }

  & .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    width: 100vw;
    height: 100vh;
    z-index: 1;

    & > h1 {
      border-bottom: 5px solid #e6e54f;
      margin-bottom: 90px;
      max-width: 300px;
      width: 100%;
      text-align: right;
      white-space: pre-wrap;
      letter-spacing: 4px;
      font-size: 60px;
      color: #224e86;
    }

    @media screen and (max-width: 414px) {
      & > h1 {
        margin-bottom: 0px;
      }
      padding-bottom: 50px;
    }

    @media screen and (max-width: 320px) {
      padding-bottom: 0px;
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      uid: Selectors.getQueryParams(ownProps).uid
    }),
    ActionCreator
  )(EditTagPage)
);
